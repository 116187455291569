import React, {useState} from "react"
import emailjs from '@emailjs/browser';
import "../../common/css/global.css"
import "./contact.css"
const Contact = ()=> {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [subject, setSubject] = useState('')
    const [subjectError, setSubjectError] = useState(false)
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState(false)
    const allLetter=(inputtxt) => {
     const letters = /^[a-zA-Z\s]*$/;
     if(inputtxt.match(letters))
       {
        return true;
       }
     else
       {
        setNameError(true)
       return false;
       }
    }
    const checkEmail =(value) => {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        return regex.test(value)
    }
    const inputValidation =(inputValue) => {
        const letterNumber = /^[0-9a-zA-Z\s]*$/;
        if(inputValue.match(letterNumber)) {
        return true;
        }
        else
        {  
        return false; 
        }
    }
    const handleInputChange = (e, field) => {
        if (field === 'name') {
            setNameError(false)
            allLetter(e.target.value) && setName(e.target.value)
            
        } else if (field === 'email') {
            setEmailError(false);
            checkEmail(e.target.value) ? setEmail(e.target.value) : setEmailError(true);
        }else if (field === 'message') {
            setMessageError(false) 
            inputValidation(e.target.value) ? setMessage(e.target.value) : setMessageError(true) 
        }else if (field === 'subject') {
            setSubjectError(false) 
            inputValidation(e.target.value) ? setSubject(e.target.value) : setSubjectError(true) 
        }
    }
    const submitEvent =(e)=> {
        e.preventDefault();
        if (!email) setEmailError(true)
        if (!name) setNameError(true)
        if(!message) setMessageError(true)
        if(!subject) setSubjectError(true)
        console.log(email, name, subject, message)
        emailjs.send("service_7odhtak","template_rvdaima",{
            from_name: `${name}`,
            email: `${email}`,
            message: `${message}`,
            subject: `${subject}`,
            }, "Bhi52lJg0ytpgay-x").then((result) => console.log(), (error) => console.log());
    }
    return (
        <div>
            <div className="page-banner">
                <img src="images/contact-banner.png" alt="banner"/>
                <div className="page-caption">
                <h2>Contact Us</h2>
                <p>Want to know more about our Services and costs, Let’s Get in Touch over a cup of coffee.</p>
                </div>
            </div>
            <div className="service-component">
                <h2 className="component-heading center">Delivering Cutting Edge technologies</h2>
                    <div className="heading-dash"/>
                    <div className="service-content">
                        <p className="content-text">Otaire is specialized in data science as a service, BI solutions, and data analytics consulting. We help enterprise companies to make better decisions on time by helping them in big data analytics and visualization. We have a dedicated team of software engineers, big data developers, data scientists, business analysts, and domain experts to help you resolve critical problems of your business with vital data. We help futuristic companies to grow faster and make fewer mistakes.</p>
                        </div>
            </div>
            <div className="conatct-email-section">
                <p className="email-heading">What can us do for you?</p>
                <div className="heading-dash"/>
                <p className="email-text">We are ready to work on a project of any complexity, whether it’s commercial or residential.</p>
                
                    <div className="email-name fleft">
                    <input type="text" placeholder="Your name *" required  onChange={ (e) => handleInputChange(e, "name") }/>
                {nameError && <p className="error-msg">Please enter your name. Only alphabets are allowed.</p>}
                    </div>
                <div className="email-field ml30 fright">
                <input type="email" placeholder="email *" required  onChange={ (e) => handleInputChange(e, "email") }/>
                {emailError && <p className="error-msg">Please enter valid email.</p>}
                </div>
                
                <input type="text" placeholder="Subject *" required className="email-subject" onChange={ (e) => handleInputChange(e, "subject") }/>
                {subjectError && <p className="error-msg">Please enter subject. Only alphabets and numbers are allowed.</p>}
                <textarea placeholder="Messages *" className="email-message" onChange={ (e) => handleInputChange(e, "message") }/>
                {messageError && <p className="error-msg">Please enter message. Only alphabets and numbers are allowed.</p>}
                <button type="submit" onClick={(e) => submitEvent(e)} className="email-submit-button">Submit Messages</button>
            
            </div>
            
        </div>
    )
}

export default Contact;