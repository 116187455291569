import React from "react";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../constant";
import "./header.css"
const Header = ({ currentPage, handleMenuClick, showMenu })=> {
    return (
        <div className="header-container">
            <div className="mobile-menu" onClick={(e)=> handleMenuClick(e)}>
                <div className="ham-menu-bar"/>
                <div className="ham-menu-bar"/>
                <div className="ham-menu-bar"/>
            </div>
            <div className="header-logo">
            <Link to={RoutePaths.home}><img src="images/logo.png" alt="logo"/></Link>
            </div>
            <div className={"header-menus " + (showMenu ? 'show-menu' : '')}>
                <nav className="header-nav">
                    <ul>
                        <li className={currentPage === RoutePaths.home ? 'active-menu' : ''}>
                            <Link to={RoutePaths.home} onClick={(e)=> handleMenuClick(e)}>Home</Link>
                        </li>
                        <li className={currentPage === RoutePaths.aboutus ? 'active-menu' : ''}>
                            <Link to={RoutePaths.aboutus} onClick={(e)=> handleMenuClick(e)}>About</Link>
                        </li>
                        <li className={currentPage === RoutePaths.services ? 'active-menu' : ''}>
                            <Link to={RoutePaths.services} onClick={(e)=> handleMenuClick(e)}>Services</Link>
                        </li>
                        <li className={currentPage === RoutePaths.careers ? 'active-menu' : ''}>
                            <Link to={RoutePaths.careers} onClick={(e)=> handleMenuClick(e)}>Careers</Link>
                        </li>
                        <li className={currentPage === RoutePaths.contact ? 'active-menu' : ''}>
                            <Link to={RoutePaths.contact} onClick={(e)=> handleMenuClick(e)}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div onClick={(e)=> handleMenuClick(e)} className="close-menu">
                    <img src="images/close.png" alt="close"/>
                </div>
            </div>
            
        </div>
    )
}

export default Header;