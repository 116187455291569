import React from "react"
import '../../common/css/global.css'
import './tab.css'

const DataMigrationContent = () => {
    return (
        <div className="tab-content">
            <h2 className="component-heading center">Data Migration</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                    <p className="content-text">Data migration is the transfer of the existing historical data to new storage, system, or file format. This process is not as simple as it may sound. It involves a lot of preparation and post-migration activities including planning, creating backups, quality testing, and validation of results. The migration ends only when the old system, database, or environment is shut down.</p>
                </div>
                <div className="tab-article">
                Otaire Solutions resources expertise on Data Architecture, Big Data Architecture, Data migration, ETL development, Hybrid Cloud development, SAP ERP, Drug Database Analytics, Market Intelligence, UX/UI Development. We provide IT development strategies and solutions for small scale to large scale companies.
                </div>
                <div className="tab-image-banner">
                    <img src="images/data-migration.svg" alt="banner" />
                </div>
                <div className="service-content">
                    <p className="content-text">Otaire is a worldwide leader in data migration services and solutions. We partner with leading enterprises, government entities and research institutions to help them leverage their most valuable asset: data. From file servers to mainframes to cloud environments – no matter where your data resides, we have the expertise and experience necessary to not only keep it there but also help you achieve positive results during a migration process that can oftentimes be risky.</p>
                </div>
        </div>
    )
}

export default DataMigrationContent;