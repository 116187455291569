import React from "react"
import "../../common/css/global.css"
import "./career.css"
const Careers = ()=> {
    return (
        <div>
            <div className="page-banner">
                <img src="images/career-banner.png" alt="banner"/>
                {/* <div className="page-caption">
                <h2>Careers</h2>
                <p>Otaire is a fast-growing global technology services company working to transform digital experiences for Fortune 100 Enterprise. Come be part of a team with exceptional people that work together to pursue one shared goal- surprise and delight the customer with a revolutionary digital experience.</p>
                </div> */}
            </div>
            <div className="career-container">
            <div className="service-component pb0">
                <h2 className="component-heading center">Careers</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                    <p className="content-text">Otaire is steadily growing. We need more resources to support the record-breaking number of active projects on our plate. We often seek new talent to join our large international team.</p>
                    <p className="content-text">Here at Otaire, we are passionate about what we do. We are constantly improving our skills and learning new ones because we believe that good enough is never enough. We are looking for people who share the same values.</p>

                    <p className="content-text">Once you are on board, be ready to take on the most challenging tasks alongside supportive colleagues in a positive work environment. As a team member, you will have opportunities to grow in your role, build a career, or find an alternative position within Otaire. Our transparent promotion process and open communication with the leadership team will help you to level up smoothly.</p>
                </div>
                
            </div>
            <div className="service-component pb0">
                <h2 className="component-heading center">Why join Otaire?</h2>
                <div className="heading-dash"/>
                <div className="service-content ul-container">
                    <p className="content-text">At Otaire, you will become part of a software engineering powerhouse with international projects collaborations with well-known corporations all over the globe. Benefits include:</p>
                    <ul>
                        <li>competitive salary</li>
                        <li>professional development</li>
                        <li>friendly and inclusive work environment</li>
                        <li>challenging tasks</li>
                        <li>innovative technologies</li>
                        <li>flexible work-life balance</li>
                    </ul>
                </div>
                
            </div>
            <div className="service-component">
            <h2 className="component-heading center">Check out our open positions</h2>
            <div className="heading-dash"/>
            <div className="service-content center">
            <p className="content-text">No Job opennings now</p>
            </div>
            
            </div>
            </div>
            
        </div>
    )
}

export default Careers;