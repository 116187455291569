import React from "react"
import '../../common/css/global.css'
import './tab.css'

const BigdataContent = () => {
    return (
        <div className="tab-content">
            <h2 className="component-heading center">Big Data/Data Science/Dataiku Architecture/Administration/Development</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                    <p className="content-text">Data has become the most critical factor in business today. As a result, different technologies, methodologies, and systems have been invented to process, transform, analyse, and store data in this data-driven world.</p>
                </div>
                <div className="tab-article">
                Otaire Solutions resources expertise on Data Architecture, Big Data Architecture, Data migration, ETL development, Hybrid Cloud development, SAP ERP, Drug Database Analytics, Market Intelligence, UX/UI Development. We provide IT development strategies and solutions for small scale to large scale companies.
                </div>
                <div className="tab-image-banner">
                    <img src="images/big-data.png" alt="banner" />
                </div>
                <div className="service-content">
                    <p className="content-text">We help clients use data to create value. We partner and consult with clients across industries to translate their big data challenges into solutions. Our services include strategic advisory, delivery of big data processing and analytics solutions, hosting managed analytics applications using Hadoop, Spark and R, big data managed services for enterprises and retailers, cloud-enabled big data platform management services and predictive machine learning solutions. The firm has worked with some of the most prominent consumer goods manufacturers (CPG), media & entertainment companies, life sciences & healthcare providers, consumer-focused businesses as well as utilities and industrials.</p>
                    <p className="content-text">Dataiku Administration is a data prep tool that includes a simple to use interface, allowing organizations of all sizes to get their feet wet with data analytics. With just one click, Dataiku can import data from any source, perform advanced transformations and clean-up on the imported data, and then store it in an existing data warehouse for further analysis.</p>
                </div>
        </div>
    )
}

export default BigdataContent;