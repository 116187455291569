import React from "react"
import '../../common/css/global.css'
import './tab.css'

const UXContent = () => {
    return (
        <div className="tab-content">
            <h2 className="component-heading center">SAP Technical & Functional</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                    <p className="content-text">SAP Technical Modules – Are modules used on the back-end of an SAP landscape to help maintain and fine-tune your environment, build applications, troubleshoot, download & install updates and plan & carry out migrations.</p>
                    <p className="content-text">SAP Functional Modules – Are modules that provide business features like processing orders, turning raw data into intelligence and handling human resources.</p>
                </div>
                <div className="tab-article">
                Otaire Solutions resources expertise on Data Architecture, Big Data Architecture, Data migration, ETL development, Hybrid Cloud development, SAP ERP, Drug Database Analytics, Market Intelligence, UX/UI Development. We provide IT development strategies and solutions for small scale to large scale companies.
                </div>
                <div className="tab-image-banner">
                    <img src="images/ux-design.svg" alt="banner" />
                </div>
                <div className="service-content">
                    <p className="content-text">Otaire is completely involved in executing SAP functional services. Our consultants have information on the most vital SAP middleware tool, SAP PI (Process Integration). Moreover, they know everything from SAP Business Connector and SAP SD & MM, SAP FICO & SFIN. This landscape is always increased with a new potential to make integration with a diverse landscape even easier and clearer implementing both Technical and Functional Tools. </p>
                </div>
        </div>
    )
}

export default UXContent;